import { type AppType } from 'next/app';
import { Inter } from 'next/font/google';
import { hotjar } from 'react-hotjar';

import { api } from '~/utils/api';

import '~/styles/globals.css';
import { useEffect } from 'react';
import { MicrosoftClarity } from '~/components/tracking/MicrosoftClarity';

const inter = Inter({
  subsets: ['latin'],
  variable: '--font-sans',
});
const MyApp: AppType = ({ Component, pageProps }) => {
  useEffect(() => {
    if (process.env.NODE_ENV === 'production') { hotjar.initialize({ id: 4965639, sv: 6 }); }
  }, []);

  return (
    <main className={`font-sans ${inter.variable}`}>
      <MicrosoftClarity />
      <Component {...pageProps} />
      <div id="modal-root" />
    </main>
  );
};

export default api.withTRPC(MyApp);
